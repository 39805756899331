.ant-message-notice {
  text-align: right;

  .ant-message-custom-content {
    display: flex;
    text-align: left;
    max-width: 350px;
    padding: 4px 2px;
    line-height: 1.5;

    i {
      font-size: 2em;
      margin-right: 15px;
    }
  }
}
