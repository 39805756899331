input[type='range'] {
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  outline: none;
}

/*Chrome*/
input[type='range']::-webkit-slider-runnable-track {
  height: 100%;
  -webkit-appearance: none;
  color: #13bba4;
  margin-top: -1px;
}
    
input[type='range']::-webkit-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  height: 1rem;
  cursor: pointer;
  background: #888;
  box-shadow: -10rem 0 0 10rem #333333;
}

/** FF*/
input[type='range']::-moz-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  height: 1rem;
  cursor: ew-resize;
  background: #888;
  box-shadow: -10rem 0 0 10rem #333333;
}

input[type="range"]::-moz-range-progress {
  background-color: #333333; 
}

input[type="range"]::-moz-range-track {  
  background-color: transparent;
}

/* IE*/

input[type="range"]::-ms-fill-lower {
  background-color: #333333; 
}
input[type="range"]::-ms-fill-upper {  
  background-color: transparent;
}