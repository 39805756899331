.slide-up-enter,
.slide-up-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.slide-up-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.slide-up-enter.slide-up-enter-active,
.slide-up-appear.slide-up-appear-active {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}
.slide-up-leave.slide-up-leave-active {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}
.slide-up-enter,
.slide-up-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-down-enter,
.slide-down-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.slide-down-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.slide-down-enter.slide-down-enter-active,
.slide-down-appear.slide-down-appear-active {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}
.slide-down-leave.slide-down-leave-active {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}
.slide-down-enter,
.slide-down-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-left-enter,
.slide-left-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.slide-left-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.slide-left-enter.slide-left-enter-active,
.slide-left-appear.slide-left-appear-active {
    -webkit-animation-name: antSlideLeftIn;
    animation-name: antSlideLeftIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}
.slide-left-leave.slide-left-leave-active {
    -webkit-animation-name: antSlideLeftOut;
    animation-name: antSlideLeftOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}
.slide-left-enter,
.slide-left-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-right-enter,
.slide-right-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.slide-right-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.slide-right-enter.slide-right-enter-active,
.slide-right-appear.slide-right-appear-active {
    -webkit-animation-name: antSlideRightIn;
    animation-name: antSlideRightIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}
.slide-right-leave.slide-right-leave-active {
    -webkit-animation-name: antSlideRightOut;
    animation-name: antSlideRightOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none;
}
.slide-right-enter,
.slide-right-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes antSlideUpIn {
    0% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
}
@keyframes antSlideUpIn {
    0% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
}
@-webkit-keyframes antSlideUpOut {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }
}
@keyframes antSlideUpOut {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }
}
@-webkit-keyframes antSlideDownIn {
    0% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 0;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
}
@keyframes antSlideDownIn {
    0% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 0;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
}
@-webkit-keyframes antSlideDownOut {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 0;
    }
}
@keyframes antSlideDownOut {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 0;
    }
}
@-webkit-keyframes antSlideLeftIn {
    0% {
        -webkit-transform: scaleX(0.8);
        transform: scaleX(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
}
@keyframes antSlideLeftIn {
    0% {
        -webkit-transform: scaleX(0.8);
        transform: scaleX(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
}
@-webkit-keyframes antSlideLeftOut {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(0.8);
        transform: scaleX(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }
}
@keyframes antSlideLeftOut {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(0.8);
        transform: scaleX(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }
}
@-webkit-keyframes antSlideRightIn {
    0% {
        -webkit-transform: scaleX(0.8);
        transform: scaleX(0.8);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 0;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
}
@keyframes antSlideRightIn {
    0% {
        -webkit-transform: scaleX(0.8);
        transform: scaleX(0.8);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 0;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
}
@-webkit-keyframes antSlideRightOut {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(0.8);
        transform: scaleX(0.8);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 0;
    }
}
@keyframes antSlideRightOut {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-slider {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    height: 12px;
    margin: 14px 6px 10px;
    padding: 4px 0;
    cursor: pointer;
    -ms-touch-action: none;
        touch-action: none;
  }
  .ant-slider-vertical {
    width: 12px;
    height: 100%;
    margin: 6px 10px;
    padding: 0 4px;
  }
  .ant-slider-vertical .ant-slider-rail {
    width: 4px;
    height: 100%;
  }
  .ant-slider-vertical .ant-slider-track {
    width: 4px;
  }
  .ant-slider-vertical .ant-slider-handle {
    margin-bottom: -7px;
    margin-left: -5px;
  }
  .ant-slider-vertical .ant-slider-mark {
    top: 0;
    left: 12px;
    width: 18px;
    height: 100%;
  }
  .ant-slider-vertical .ant-slider-mark-text {
    left: 4px;
    white-space: nowrap;
  }
  .ant-slider-vertical .ant-slider-step {
    width: 4px;
    height: 100%;
  }
  .ant-slider-vertical .ant-slider-dot {
    top: auto;
    left: 2px;
    margin-bottom: -4px;
  }
  .ant-slider-with-marks {
    margin-bottom: 28px;
  }
  .ant-slider-rail {
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #f5f5f5;
    border-radius: 2px;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .ant-slider-track {
    position: absolute;
    height: 4px;
    background-color: #91d5ff;
    border-radius: 4px;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .ant-slider-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    background-color: #fff;
    border: solid 2px #91d5ff;
    border-radius: 50%;
    -webkit-box-shadow: 0;
            box-shadow: 0;
    cursor: pointer;
    -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: border-color 0.3s, -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  }
  .ant-slider-handle:focus {
    border-color: #46a6ff;
    outline: none;
    -webkit-box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.2);
            box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.2);
  }
  .ant-slider-handle.ant-tooltip-open {
    border-color: #1890ff;
  }
  .ant-slider:hover .ant-slider-rail {
    background-color: #e1e1e1;
  }
  .ant-slider:hover .ant-slider-track {
    background-color: #69c0ff;
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #69c0ff;
  }
  .ant-slider-mark {
    position: absolute;
    top: 14px;
    left: 0;
    width: 100%;
    font-size: 14px;
  }
  .ant-slider-mark-text {
    position: absolute;
    display: inline-block;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    word-break: keep-all;
    cursor: pointer;
  }
  .ant-slider-mark-text-active {
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
  }
  .ant-slider-dot {
    position: absolute;
    top: -2px;
    width: 8px;
    height: 8px;
    margin-left: -4px;
    background-color: #fff;
    border: 2px solid #e8e8e8;
    border-radius: 50%;
    cursor: pointer;
  }
  .ant-slider-dot:first-child {
    margin-left: -4px;
  }
  .ant-slider-dot:last-child {
    margin-left: -4px;
  }
  .ant-slider-dot-active {
    border-color: #8cc8ff;
  }
  .ant-slider-disabled {
    cursor: not-allowed;
  }
  .ant-slider-disabled .ant-slider-track {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }
  .ant-slider-disabled .ant-slider-handle,
  .ant-slider-disabled .ant-slider-dot {
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.25) !important;
    -webkit-box-shadow: none;
            box-shadow: none;
    cursor: not-allowed;
  }
  .ant-slider-disabled .ant-slider-mark-text,
  .ant-slider-disabled .ant-slider-dot {
    cursor: not-allowed !important;
  }
  