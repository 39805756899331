iframe#launcher {
  margin-bottom: 15px!important;

  @media screen and (min-width: 450px) and (max-width: 600px) {
    margin-bottom: 15px!important;
  }

  @media screen and (min-width: 601px) {
    margin: 40px 20px!important;
  }
}
