.ant-tooltip {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
    position: absolute;
    z-index: 1060;
    display: block;
    max-width: 250px;
    visibility: visible;
}
.ant-tooltip-hidden {
    display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
    padding-bottom: 8px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
    padding-left: 8px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
    padding-top: 8px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
    padding-right: 8px;
}
.ant-tooltip-inner {
    min-width: 30px;
    min-height: 32px;
    padding: 6px 8px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: #333333; //rgba(0, 0, 0, 0.75)
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-tooltip-arrow {
    position: absolute;
    display: block;
    width: 7.07106781px;
    height: 7.07106781px;
    background: transparent;
    border-style: solid;
    border-width: 3.53553391px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
    bottom: 5.2px;
    border-top-color: transparent;
    border-right-color: #333333; //404040
    border-bottom-color: #333333; //404040
    border-left-color: transparent;
    -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
    -ms-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
    left: 16px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
    right: 16px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
    left: 5px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #404040;
    border-left-color: #404040;
    -webkit-box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
    box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
    -ms-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
    top: 8px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
    bottom: 8px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
    right: 5px;
    border-top-color: #404040;
    border-right-color: #404040;
    border-bottom-color: transparent;
    border-left-color: transparent;
    -webkit-box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
    box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
    -ms-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
    top: 8px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
    bottom: 8px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    top: 5px;
    border-top-color: #404040;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #404040;
    -webkit-box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
    -ms-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
    left: 16px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    right: 16px;
}

.ant-menu-inline-collapsed-tooltip {
    pointer-events: none;
}
.ant-menu-inline-collapsed-tooltip .anticon {
    display: none;
}
.ant-menu-inline-collapsed-tooltip a {
    color: rgba(255, 255, 255, 0.85);
}

.ant-slider-handle.ant-tooltip-open {
    border-color: #1890ff;
  }
  .ant-slider:hover .ant-slider-rail {
    background-color: #e1e1e1;
  }
  .ant-slider:hover .ant-slider-track {
    background-color: #69c0ff;
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #69c0ff;
  }